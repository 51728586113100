import './index.scss'
import AnimatedLetters from '../AnimatedLetters'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAndroid,
  faGithubAlt,
  faJsSquare,
  faPhp,
} from '@fortawesome/free-brands-svg-icons'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      return setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p>
            I'm a passionate developer with expertise in .NET, Web, and Java technologies. My journey in the world of programming
            has been driven by a curiosity to solve problems and a love for creating innovative solutions.
          </p>
          <p>
              In the .NET world, I thrive on crafting robust and scalable applications using C# and the .NET framework.
              On the web, I enjoy working with HTML, CSS, and JavaScript to build responsive and user-friendly interfaces.
              Additionally, my Java skills extend to developing versatile and efficient applications.
          </p>
          <p>
              Whether it's designing elegant user interfaces, implementing backend logic, or optimizing database performance,
              I'm dedicated to delivering high-quality software that meets both user needs and industry standards.
          </p>
          <p>
              Let's connect and collaborate! Feel free to reach out to me on <a href="https://www.linkedin.com/in/michael-morris-79611a189/" >LinkedIn</a> or check out my code on <a href="https://github.com/mikegn00" >GitHub</a>.
          </p>
        </div>

        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faPhp} color="#484C89" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faAndroid} color="#3DDC84" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faGithubAlt} color="#FFF" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default About
