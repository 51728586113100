import { Link } from "react-router-dom";
import './index.scss';
import { useEffect, useState } from "react";
import AnimatedLetters from "../AnimatedLetters";

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['I', ' ', 'a', 'm', ' ', 'M', 'i', 'c', 'h', 'a', 'e', 'l']
    const jobArray = ['M', 'o', 'r', 'r', 'i', 's' ]

    useEffect(() => {
        setTimeout(() => {
            return setLetterClass('text-animate-hover')
        }, 4000)
    }, [])

    return (
        <div className="container home-page">
            <div className="text-zone">
                <h1>Hi, <br/>
                <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} />
                <img src="" alt=''/>
                <br/>
                <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22} />
                </h1>
                <h2>.Net / Web / Java Developer</h2>
                <Link to="/contact" className="flat-button">CONTACT ME &gt;</Link>
            </div>
        </div>
    )
}

export default Home